import { React, useState } from "react";
import { Form, Container, Row, Col, InputGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import "./Search.css";
import logo from "./DAL/youtube-logo.png";

function Search() {
  const validators = {
    defIcon: <i className="em em-tv" aria-label="TELEVISION"></i>,
    nudgeError: <i className="em em-x" aria-label="CROSS MARK"></i>,
    nudgeValid: (
      <i
        className="em em-white_check_mark"
        aria-label="WHITE HEAVY CHECK MARK"
      ></i>
    ),
  };
  const [vidId, setVidId] = useState({
    vid: "p2vpqKBPj4U",
    icon: validators.defIcon,
  });

  const checkInput = (e) => {
    if (e.target.value.length > 1) {
      const validVid = e.target.value;
      const format =
        /https(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
      if (format.test(validVid)) {
        setVidId({
          vid: e.target.value.split("=")[1],
          icon: validators["nudgeValid"],
        });
      } else {
        setVidId({ vid: "p2vpqKBPj4U", icon: validators["nudgeError"] });
      }
    } else {
      setVidId({ vid: "p2vpqKBPj4U", icon: validators["defIcon"] });
    }
  };

  const clipboardCopy = () => {
    toast.success(`https://www.snap-vid.com/?vid=${vidId["vid"]}`);
    navigator.clipboard.writeText(
      `https://www.snap-vid.com/?vid=${vidId["vid"]}`
    );
  };

  return (
    <Container className="search-container">
      <Row className="logo-row">
        <Col>
          <img alt="img" src={logo} className="youtube-logo" />
          <span>Watch Ad Free Videos!</span>
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{vidId["icon"]}</InputGroup.Text>
            <Form.Control
              placeholder="https://www.youtube.com/watch?v=XXX"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="search-box"
              onChange={(e) => checkInput(e)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <iframe
            className="iframe-box"
            width="100%"
            height="600px"
            src={`https://www.youtube.com/embed/${vidId["vid"]}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
      <Row>
        <Col className="copy-link">
          <span
            className="copy-txt"
            onClick={() => {
              clipboardCopy();
            }}
          >
            COPY LINK
          </span>
          <i
            className="fa-regular fa-copy copy-icon"
            onClick={() => {
              clipboardCopy();
            }}
          ></i>

          <Toaster
            containerStyle={{
              position: "bottom-center	",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="catch-phrase" align="center">
          <span>
            Fast{" "}
            <i className="em em-checkered_flag" aria-label="CHEQUERED FLAG"></i>{" "}
            Free{" "}
            <i
              className="em em-money_with_wings"
              aria-label="MONEY WITH WINGS"
            ></i>{" "}
            Friendly{" "}
            <i
              className="em em-heart_eyes"
              aria-label="SMILING FACE WITH HEART-SHAPED EYES"
            ></i>
          </span>
        </Col>
      </Row>
    </Container>
  );
}

export default Search;
